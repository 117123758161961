import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import BigButton from '../components/BigButton/BigButton'
import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'

import '../styles/policy.css'
import '../styles/home.css'

const PolicyAndResearch = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  return (
    <Layout title={pageData.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={pageData.headerImage.photo} alt={pageData.headerImage.altText}>{pageData.heading}</Header>
      <div className="main">


        <div className="flex-row cta-row">
          {data.markdownRemark.frontmatter.buttons.map(button => (
            <BigButton link={button.link} name={button.name} photo={button.photo} external={button.external} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query PolicyAndResearchQuery {
  markdownRemark(frontmatter: {title: {eq: "Policy and Research"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      videoArea {
        subTitle
        video
        info
      }
      buttons {
        link
        name
        external
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
    }
  }
}
`

export default PolicyAndResearch